<template>
  <div class="front-cont">
    <div v-if="!searched" class="img-bg row py-4 f-page-bg" style="opacity: 0.95">
      <div class="f-disclosure-content">
        <div class="p-3 p-md-4 bg-white disclosure-box">
          <div class="d-flex align-items-center">
            <h1 class="section-title text-dark mb-1">
              {{ $t.translate("LBL_SEARCH_BY") }}
            </h1>
          </div>

          <div class="mt-3">
            <div class="d-flex align-items-center mb-2">
              <label for="disc-kw" class="field-label">{{
                $t.translate("FRM_KEYWORD_SEARCH")
              }}</label>
              <el-input
                v-model="formData.Keyword"
                :placeholder="$t.translate('FRM_TYPE_HERE')"
                class="search-input acumin-font"
                id="disc-kw"
              ></el-input>
            </div>

            <div class="d-flex align-items-center mb-2">
              <label for="disc-sn" class="field-label">{{
                $t.translate("FRM_ASSESSMENT_SERVICE_NAME")
              }}</label>
              <el-select
                v-model="formData.AssessmentServiceKey"
                class="disclosure-select acumin-font w-100"
                :placeholder="$t.translate('FRM_SELECT')"
                popper-class="front-popper"
                id="disc-sn"
              >
                <el-option
                  v-for="item in ServiceOpts"
                  :key="item.AssessmentServiceKey"
                  :label="$h.formatServiceName(item)"
                  :value="item.AssessmentServiceKey"
                >
                </el-option>
              </el-select>
            </div>

            <div class="d-flex align-items-center mb-2">
              <label for="disc-cno" class="field-label">{{
                $t.translate("LBL_CERT_NO")
              }}</label>
              <el-input
                v-model="formData.CertificateNo"
                placeholder="CR _ _ _ _"
                class="search-input acumin-font"
                id="disc-cno"
              ></el-input>
            </div>

            <div class="d-flex align-items-center mb-2">
              <label for="disc-stno" class="field-label">{{
                $t.translate("LBL_STATEMENT_NO")
              }}</label>
              <el-input
                v-model="formData.StatementNo"
                :placeholder="$t.translate('TYPE_HERE_TO_SEARCH')"
                class="search-input acumin-font"
                id="disc-stno"
              ></el-input>
            </div>

            <div class="d-flex align-items-center mb-2">
              <label for="disc-indu" class="field-label">{{
                $t.translate("FRM_INDUSTRY")
              }}</label>
              <el-select
                v-model="formData.IndustryKey"
                class="disclosure-select acumin-font w-100"
                :placeholder="$t.translate('FRM_SELECT')"
                popper-class="front-popper"
                id="disc-indu"
              >
                <el-option
                  v-for="item in IndustryOpts"
                  :key="item.IndustryKey"
                  :label="$h.formatName(item, 'Name')"
                  :value="item.IndustryKey"
                >
                </el-option>
              </el-select>
            </div>

            <div class="d-flex justify-content-center mb-2">
              <el-button
                type="info"
                class="f-btn-common f-btn-orange mt-4"
                style="min-width: 200px"
                @click="searchNow"
              >
                <b>{{ $t.translate("BTN_SEARCH") }}</b
                ><i class="ml-1 el-icon-arrow-right"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="f-disclosure-list">
      <div class="px-4 px-md-5 px-lg-7 py-4 row f-list-bg">
        <div class="col-md-3 px-0 bg-transparent pt-5 pt-md-0">
          <div class="px-3 px-md-4 py-2 py-md-3 bg-white gen-bd-radius">
            <div class="d-flex align-items-center">
              <h2 class="text-dark mb-0">{{ $t.translate("LBL_SEARCH_BY") }}</h2>
            </div>

            <div class="mt-2 pt-1">
              <div class="mb-4">
                <label for="disc-kw" class="field-label gray-lb">{{
                  $t.translate("FRM_KEYWORD_SEARCH")
                }}</label>
                <el-input
                  v-on:keyup.enter="searchNow"
                  v-model="formData.Keyword"
                  :placeholder="$t.translate('FRM_TYPE_HERE')"
                  class="search-input acumin-font inner-bd"
                  id="disc-kw"
                ></el-input>
              </div>

              <div class="mb-4">
                <div class="clearable-title">
                  <label class="field-label gray-lb mb-2" style="min-width: unset">{{
                    $t.translate("FRM_ASSESSMENT_SERVICE_NAME")
                  }}</label>
                  <el-button
                    @click="clear('AssessmentServiceKey')"
                    type="text"
                    class="pb-0 mb-2 f-text-orange"
                    >{{ $t.translate("BTN_CLEAR") }}</el-button
                  >
                </div>

                <el-radio-group
                  @change="searchNow"
                  v-model="formData.AssessmentServiceKey"
                  class="disclosure-opt acumin-font"
                  aria-label="group"
                >
                  <el-radio
                    v-for="item in ServiceOpts"
                    :key="item.AssessmentServiceKey"
                    :label="item.AssessmentServiceKey"
                    class="w-100"
                    role=""
                  >
                    {{ $h.formatServiceName(item) }}
                  </el-radio>
                </el-radio-group>
              </div>

              <div class="mb-4">
                <label for="disc-cno" class="field-label gray-lb">{{
                  $t.translate("LBL_CERT_NO")
                }}</label>
                <el-input
                  v-on:keyup.enter="searchNow"
                  v-model="formData.CertificateNo"
                  placeholder="CR _ _ _ _"
                  class="search-input acumin-font inner-bd"
                  id="disc-cno"
                ></el-input>
              </div>

              <div class="mb-4">
                <label for="disc-stno" class="field-label gray-lb">{{
                  $t.translate("LBL_STATEMENT_NO")
                }}</label>
                <el-input
                  v-on:keyup.enter="searchNow"
                  v-model="formData.StatementNo"
                  :placeholder="$t.translate('TYPE_HERE_TO_SEARCH')"
                  class="search-input acumin-font inner-bd"
                  id="disc-stno"
                ></el-input>
              </div>

              <div class="mb-4">
                <div class="clearable-title">
                  <label class="field-label gray-lb mb-2" style="min-width: unset">{{
                    $t.translate("FRM_INDUSTRY")
                  }}</label>
                  <el-button
                    @click="clear('IndustryKey')"
                    type="text"
                    class="pb-0 mb-2 f-text-orange"
                    >{{ $t.translate("BTN_CLEAR") }}</el-button
                  >
                </div>

                <el-radio-group
                  @change="searchNow"
                  v-model="formData.IndustryKey"
                  class="disclosure-opt acumin-font"
                  aria-label="group"
                >
                  <el-radio
                    v-for="item in IndustryOpts"
                    :key="item.IndustryKey"
                    :label="item.IndustryKey"
                    class="w-100"
                    role=""
                  >
                    {{ $h.formatName(item, "Name") }}
                  </el-radio>
                </el-radio-group>
              </div>

              <div class="d-flex justify-content-center mb-2">
                <el-button
                  type="info"
                  class="f-btn-common f-btn-orange w-100"
                  @click="searchNow"
                >
                  <b>{{ $t.translate("BTN_SEARCH") }}</b
                  ><i class="ml-1 el-icon-arrow-right"></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-loading="isSearching"
          class="col-md-9 px-3 px-md-4 pt-4 pt-md-0 bg-transparent spinner-cont"
        >
          <el-alert
            v-if="!isSearching && !Pagination.Items.length"
            :title="$t.translate('LBL_NO_RECORD_FOUND')"
            show-icon
            :closable="false"
          ></el-alert>

          <div
            v-for="(item, index) in Pagination.Items"
            :key="index"
            class="row disclosure-card"
            :class="{
              orange: index % 3 == 0,
              blue: index % 3 == 1,
              green: index % 3 == 2,
              'mt-2': index != 0,
            }"
          >
            <div class="col-md-12 bg-white px-0 d-flex">
              <div style="height: 100%" class="pl-3 title-head-bg" />

              <div
                class="d-flex w-100 px-3 px-md-4 py-3 py-md-2 align-items-center justify-content-between"
              >
                <h2 class="f-text-sec-gray mb-0">
                  {{
                    $h.formatServiceName({
                      Name: item.ServiceName,
                      NameCHS: item.ServiceNameCHS,
                      NameCHT: item.ServiceNameCHT,
                      NameExtra: item.ServiceNameExtra,
                      NameExtraCHS: item.ServiceNameExtraCHS,
                      NameExtraCHT: item.ServiceNameExtraCHT,
                    })
                  }}
                </h2>
                <img
                  v-if="$h.formatName(item, 'Logo')"
                  :src="
                    $axios.formulateUrl(`/image/view/tmp/${$h.formatName(item, 'Logo')}`)
                  "
                  class="brand-img ml-2"
                  alt="..."
                />
              </div>
            </div>

            <div class="col-md-12 px-0 children-bg gen-bdb-radius">
              <div
                v-for="child in item.Children"
                :key="child.CertificateKey"
                class="bg-white my-3 mx-3 disclosure-subcard"
              >
                <div class="px-3 px-md-4 py-3">
                  <div class="row">
                    <div class="col-md-4 mt-0 mt-md-1">
                      <p class="field-lbl font-weight-bold">
                        {{ $t.translate("FRM_ORGANIZATION_NAME") }}
                      </p>
                      <p class="f-text-gray smaller">
                        {{ $h.formatName(child, "OrgName") }}
                      </p>
                    </div>
                    <div v-if="child.BranchName" class="col-md-4 mt-2 mt-md-1">
                      <p class="field-lbl font-weight-bold">
                        {{ $t.translate("FRM_BRANCH") }}
                      </p>
                      <p class="f-text-gray smaller">
                        {{ $h.formatName(child, "BranchName") }}
                      </p>
                    </div>
                    <div v-if="child.Scope" class="col-md-4 mt-2 mt-md-1">
                      <p class="field-lbl font-weight-bold">
                        {{ $t.translate("FRM_SCOPE") }}
                      </p>
                      <p class="f-text-gray smaller">
                        {{ $h.formatName(child, "Scope") }}
                      </p>
                    </div>
                  </div>

                  <div v-if="!child.IsExpanded" class="pt-2"></div>
                  <template v-else>
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{ $t.translate("FRM_ADDRESS") }}
                        </p>
                        <p class="f-text-gray smaller">
                          {{ $h.formatName(child, "OrgAddress") }}
                        </p>
                      </div>

                      <div class="col-md-4 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{
                            child.Type == "CERTIFICATE"
                              ? $t.translate("LBL_CERT_NO")
                              : $t.translate("LBL_STATEMENT_NO")
                          }}
                        </p>
                        <p class="f-text-gray smaller">{{ child.CertificateNo }}</p>
                      </div>
                      <div class="col-md-4 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{
                            child.Type == "CERTIFICATE"
                              ? $t.translate("TTL_CERT_ISSUANCE_DATE")
                              : $t.translate("TTL_STATEMENT_ISSUANCE_DATE")
                          }}
                        </p>
                        <p class="f-text-gray smaller">
                          {{ $h.formatDisclosureDate(child.IssuedDate) }}
                        </p>
                      </div>
                      <div v-if="child.ExpiryDateTime" class="col-md-4 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{
                            child.Type == "CERTIFICATE"
                              ? $t.translate("TTL_CERT_EXPIRY_DATE")
                              : $t.translate("TTL_STATEMENT_EXPIRY_DATE")
                          }}
                        </p>
                        <p class="f-text-gray smaller">
                          {{ $h.formatDisclosureDate(child.ExpiryDateTime) }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{
                            child.Type == "CERTIFICATE"
                              ? $t.translate("FRM_CERT")
                              : $t.translate("FRM_STATEMENT")
                          }}
                        </p>
                        <p class="f-text-gray smaller">
                          <a
                            href="javascript:;"
                            @click="
                              $refs.export.exportContent({
                                Public: true,
                                Mode: 'GENERATE_CERTIFICATE',
                                Filter: {
                                  ApplicationKey: child.Application.ApplicationKey,
                                },
                                PDFOnly: true,
                              })
                            "
                            class="link-bd c-pointer"
                            >{{ $t.translate("BTN_LINK") }}</a
                          >
                        </p>
                      </div>
                      <div
                        v-if="
                          child?.Application &&
                          child?.Application?.IsHaveDisclosureDocument
                        "
                        class="col-md-4 mt-2"
                      >
                        <p class="field-lbl font-weight-bold">
                          {{ $t.translate("LBL_DISCLOSURE_DOCUMENT") }}
                        </p>
                        <p class="f-text-gray smaller">
                          <a
                            @click="
                              $refs.export.exportContent({
                                Public: true,
                                Mode: 'GENERATE_MACHFORM_ENTRY',
                                ApplicationKey: child.Application.ApplicationKey,
                                MachformEntryKey: child.Application.MachformEntryKey,
                                MachformId: child.Application.MachformId,
                                PDFOnly: true,
                              })
                            "
                            href="javascript:;"
                            class="link-bd c-pointer"
                            >{{ $t.translate("BTN_LINK") }}</a
                          >
                        </p>
                      </div>

                      <div v-if="child?.Application" class="col-md-4 mt-2">
                        <p class="field-lbl font-weight-bold">
                          {{ $t.translate("LBL_REMARK") }}
                        </p>
                        <p class="f-text-gray smaller">
                          {{ $h.formatName(child.Application, "Remark") || "N/A" }}
                          <!-- <GeneralCommentsPlain
                            :skipLoader="true"
                            :identifierkey="child.Application.ApplicationKey"
                            type="DISCLOSURE"
                          /> -->
                        </p>
                      </div>
                    </div>
                  </template>
                </div>

                <div style="width: 100%" class="pt-2 title-head-bg position-relative">
                  <div
                    class="expand-mark-cont c-pointer"
                    @click="child.IsExpanded = !child.IsExpanded"
                  >
                    <i
                      class="field-lbl font-weight-bold"
                      :class="{
                        'el-icon-arrow-down': !child.IsExpanded,
                        'el-icon-arrow-up': child.IsExpanded,
                      }"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 px-md-7 py-3 row f-bg-blue align-items-center pagi-section">
        <div class="col-xl-4 col-lg-6 px-0 d-flex align-items-center">
          <div class="d-flex align-items-center bg-white px-3 gen-bd-radius">
            <h4 class="f-text-blue mb-0">{{ $t.translate("LBL_SHOW") }}</h4>
            <el-select
              @change="loadItems(1)"
              v-model="Pagination.PageSize"
              placeholder=""
              class="general-select"
              style="width: 70px"
              popper-class="front-popper"
            >
              <el-option
                v-for="item in $configs.pageSizeOptions"
                :label="$t.translate(item.label)"
                :key="item.value"
                :value="item.value"
              />
            </el-select>

            <h4 class="f-text-blue ml-2 mb-0">{{ $t.translate("LBL_ENTRIES") }}</h4>
          </div>
        </div>

        <div class="col-xl-4 col-lg-6 px-0 py-3 py-xl-0">
          <h4 class="text-white text-center mb-0">
            {{
              $t
                .translate("LBL_LIST_SIZE_ORG")
                .replace("{LISTSIZE}", Pagination.ItemsTotal)
            }}
          </h4>
        </div>

        <div
          v-if="Pagination.Items.length"
          class="col-xl-4 col-lg-6 px-0 d-flex justify-content-xl-end pr-2"
        >
          <el-pagination
            v-model:current-page="Pagination.CurrentPage"
            :layout="mq.current == 'xsmall' ? 'prev, next, jumper' : 'prev, pager, next'"
            :total="Pagination.ItemsTotal"
            :pager-count="5"
            :page-size="Pagination.PageSize"
            :prev-text="$t.translate('BTN_PREV')"
            :next-text="$t.translate('BTN_NEXT')"
            class="f-gen-pagination"
            @current-change="loadItems"
            role=""
          >
          </el-pagination>
        </div>
      </div>

      <div
        style="background: rgba(22, 115, 255, 0.1)"
        class="px-4 px-md-7 py-3 row align-items-center"
      >
        <div class="col-12 px-0 pr-2">
          <DisclosureDisclaimer :key="`currentlang-${$t.getLanguage()}`" />
        </div>
      </div>

      <!-- <div class="pb-4 row f-list-bg"></div> -->

      <el-backtop class="back-top-mark">
        <img src="/img/front/to_top.png" class="w-100" alt="..." />
      </el-backtop>
    </div>
  </div>

  <ExportModal :types="['PDF']" ref="export" />
</template>
<script>
// import GeneralCommentsPlain from "@/components/GeneralCommentsPlain";
import DisclosureDisclaimer from "@/components/base/DisclosureDisclaimer";
import ExportModal from "@/components/ExportModal";
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { useMq } from "vue3-mq";
import { extendedConfigs } from "@/utils/configs";
import dayjs from "dayjs";

export default {
  components: {
    DisclosureDisclaimer,
    //GeneralCommentsPlain,
    ExportModal,
  },
  setup() {
    const route = useRoute();
    const mq = useMq();
    let { defaultSize } = extendedConfigs;

    let searched = ref(route.query.searched ? true : false);
    let formData = reactive({
      Keyword: "",
      AssessmentServiceKey: route.query.service || "",
      CertificateNo: "",
      StatementNo: "",
      IndustryKey: "",
    });

    let ServiceOpts = ref([]);
    let IndustryOpts = ref([]);

    let Pagination = reactive({
      Items: [],
      ItemsTotal: 0,
      PageSize: defaultSize,
      CurrentPage: 1,
    });
    let isSearching = ref(false);

    onMounted(async () => {
      let res = await get("/public/industry", {}, true);
      IndustryOpts.value = res?.IndustryList || [];

      let resp = await get("/public/service", { page: 1, size: 9999 }, true);
      ServiceOpts.value = resp?.data || [];

      if (route.query.searched) {
        searchNow();
      }
    });

    onUpdated(() => {});

    const loadItems = async (page) => {
      if (page) {
        Pagination.CurrentPage = page;
      }
      try {
        isSearching.value = true;
        let res = await get(
          "/public/certificate",
          {
            page: Pagination.CurrentPage,
            size: Pagination.PageSize,
            ...formData,
          },
          true
        );

        Pagination.ItemsTotal = res?.totalData;

        Pagination.Items = _.map(
          _.groupBy(res.data, (r) => r.AssessmentServiceKey),
          (r) => {
            return {
              ...r[0],
              Children: _.sortBy(r, (a) => dayjs(a.LastCreated)).reverse(),
            };
          }
        );

        //Pagination.Items = res?.data || [];

        isSearching.value = false;
      } catch (err) {
        isSearching.value = false;
      }
      searched.value = true;
    };

    const searchNow = async () => {
      await loadItems(1);
    };

    const viewCertLink = async (child) => {
      window.open(child.CertificateLink);
    };

    const viewDocumentLink = async (child) => {
      window.open(child.DisclosureDocumentLink);
    };

    const clear = (field) => {
      formData[field] = "";
      searchNow();
    };

    return {
      clear,
      searched,
      mq,
      formData,
      ServiceOpts,
      IndustryOpts,
      Pagination,
      isSearching,
      loadItems,
      searchNow,
      viewCertLink,
      viewDocumentLink,
    };
  },
};
</script>
<style>
.statement-text {
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  color: #fff;
}
.clearable-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f-disclosure-content {
  /* position: absolute; */
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-bg {
  background-size: cover;
  min-height: 80vh;
}
.disclosure-box {
  border-radius: 8px;
  width: 600px;
  opacity: 0.92;
}
.front-cont .field-label {
  font-size: var(--f-size-label);
  min-width: 180px;
  color: #333;
}
.front-cont .field-label.gray-lb {
  color: #707070;
  font-family: Arial !important;
  font-weight: bold;
  font-size: var(--f-size-h4);
}
.front-cont .acumin-font .el-input__inner {
  font-family: AcuminConcept !important;
  height: var(--fr-input-height);
}
.front-cont .inner-bd .el-input__inner {
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  height: var(--fr-input-height);
}
.disclosure-opt .el-radio__label {
  font-family: AcuminConcept !important;
}
.disclosure-opt {
  overflow: auto;
  max-height: 300px;
}
.f-disclosure-list .f-gen-pagination.el-pagination {
  background: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
}
.f-disclosure-list .f-gen-pagination.el-pagination .el-pager .number {
  border: 1px solid #fff;
}
.f-disclosure-list .f-gen-pagination.el-pagination .el-pager .number.active {
  background: #fff;
  border: 1px solid #0a6cff;
}
.f-disclosure-list .f-gen-pagination.el-pagination .btn-prev {
  padding-left: 12px;
  padding-right: 3px;
}
.f-disclosure-list .f-gen-pagination.el-pagination .btn-next {
  padding-left: 3px;
  padding-right: 12px;
}
.f-disclosure-list .f-gen-pagination.el-pagination .btn-prev *,
.f-disclosure-list .f-gen-pagination.el-pagination .btn-next * {
  font-size: var(--f-size-h4);
  font-family: Arial !important;
  font-weight: bold;
}
.f-disclosure-list .f-gen-pagination.el-pagination * {
  color: #0a6cff;
}
.f-disclosure-list .brand-img {
  max-height: 50px;
}
.front-cont .disclosure-card.blue .title-head-bg {
  background: #0a6cff;
}
.front-cont .disclosure-card.orange .title-head-bg {
  background: #f1832d;
}
.front-cont .disclosure-card.green .title-head-bg {
  background: #3e8028;
}
.front-cont .disclosure-card.blue .children-bg {
  background: rgba(22, 115, 255, 0.1);
}
.front-cont .disclosure-card.orange .children-bg {
  background: rgba(241, 131, 45, 0.1);
}
.front-cont .disclosure-card.green .children-bg {
  background: rgba(62, 128, 40, 0.1);
}
.front-cont .disclosure-card.blue .field-lbl {
  color: #0a6cff;
}
.front-cont .disclosure-card.orange .field-lbl {
  color: #c25115;
}
.front-cont .disclosure-card.orange .expand-mark-cont .field-lbl {
  color: #f1832d;
}
.front-cont .disclosure-card.green .field-lbl {
  color: #3e8028;
}
.front-cont .disclosure-subcard *:not(i) {
  font-family: AcuminConcept !important;
}
.link-bd {
  border-bottom: 1px solid #707070;
}
.expand-mark-cont {
  background: #fff;
  border-radius: 30px;
  position: absolute;
  height: 28px;
  width: 28px;
  right: 25px;
  bottom: -8px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.front-cont .disclosure-card.blue .expand-mark-cont {
  border: 2px solid #0a6cff;
}
.front-cont .disclosure-card.orange .expand-mark-cont {
  border: 2px solid #f1832d;
}
.front-cont .disclosure-card.green .expand-mark-cont {
  border: 2px solid #3e8028;
}
.spinner-cont .el-loading-spinner {
  top: 20% !important;
}

@media (max-width: 900px) {
  .disclosure-box {
    width: 88%;
    margin-top: var(--mt-disclosure-box);
  }
  .front-cont .field-label {
    font-size: var(--f-size-label);
    min-width: 95px;
    margin-right: 8px;
  }
}
</style>
